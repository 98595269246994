<template>
  <div id="app">
    <vue-editor
      id="editor"
      :value="content"
      useCustomImageHandler
      :editorToolbar="customToolbar"
      @image-added="handleImageAdded"
      v-model="htmlForEditor"
      use-custom-image-handler
      @input="editorInput"
      @blur="onEditorBlur"
      placeholder="可输入文字或上传图片"
    >
    </vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
// import { ImageDrop } from './ImagePaste.js'
import { ossUploadFile } from "@/api/public";
const { baseUrl } = require("@/config");
export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      content: "",
      baseUrl: baseUrl,
      htmlForEditor: "",
      customToolbar: [
        ["bold", "italic", "underline", "clean"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["image"],
      ],
      imglength: 0,
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.value != this.content) {
          this.content = this.value;
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      if (this.imglength <= 20) {
        var maxsize = 10 * 1024 * 1024;
        if (file.size >= maxsize) {
          this.$message({
            type: "info",
            message: "图片大小不得大于10M",
          });
          return false;
        }
        var formData = new FormData();
        formData.append("file", file);
        console.log(file);
        ossUploadFile(formData).then((res) => {
          console.log(res);
          let url = baseUrl + "third/third/ossGetFile/" + res.data; // Get url from response
          console.log(url);
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        });
      } else {
        this.$message({
          type: "info",
          message: "最多只能上传20张图片",
        });
      }
    },
    editorInput(val) {
      this.content = val;
      if (val.match(/img/g)) {
        this.imglength = val.match(/img/g).length;
      }
      this.$emit("input", val);
    },
    onEditorBlur() {
      console.log("7777");
      this.$emit("blurfun");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ql-editor {
    img {
      width: 100px;
    }
  }
}
</style>