<template>
  <div class="issueServe">
    <div>商品图片<span class="issu_imagenum">(最多上传5张图片)</span></div>
    <div class="issue_up">
      <el-form
        :rules="rules"
        ref="receivingForm"
        :model="formData"
        label-width="40px"
      >
        <el-form-item prop="imgUrlList" label=" ">
          <el-upload
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :http-request="addinvoiceUrl"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :limit="5"
            :disabled="formData.imgUrlList.length >= 5"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>默认为商品主图</div>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <div class="line">
          <el-form-item label=" " prop="dictId">
            <div class="select_dict">
              <img src="../../../assets/zxx_img/fuwu.png" alt="" />
              <el-select v-model="formData.dictId" placeholder="请选择服务范围">
                <el-option
                  v-for="item in options"
                  :key="item.dictId"
                  :label="item.dictValue"
                  :value="item.dictId"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label=" " prop="name" style="margin-left: 30px">
            <div class="icon_png">
              <img src="../../../assets/zxx_img/icons_yinhang.png" alt="" />
              <el-input
                v-model.trim="formData.name"
                placeholder="请输入服务名称"
                maxlength="100"
                @input="nameinput"
              >
              </el-input>
            </div>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="concateUser">
            <el-input
              v-model.trim="formData.concateUser"
              placeholder="请输入联系人名称"
              type="text"
              maxlength="20"
              prefix-icon="hy-icon-shenfenzheng"
              @input="concateUserInput"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="phone" style="margin-left: 30px">
            <el-input
              v-model.trim="formData.phone"
              placeholder="请输入联系电话"
              onkeyup="value=value.replace(/[^0-9]/g,'')"
              onpaste="value=value.replace(/[^0-9]/g,'')"
              oncontextmenu="value=value.replace(/[^0-9]/g,'')"
              maxlength="11"
              @input="phoneInput"
              prefix-icon="hy-icon-lianxidianhua-xianxing"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label="" prop="price">
            <span style="margin-left: 20px; color: red">*</span>
            <el-input
              maxlength="6"
              style="width: 200px; margin-left: 15px; position: relative"
              v-model.trim="formData.price"
              placeholder="请输入价格"
              prefix-icon="hy-icon-jiage1"
              @input="priceInput"
              :disabled="checked"
            ></el-input>
            <div style="position: absolute; top: 0; right: 12px;color:#ccc">元</div>
          </el-form-item>
          <el-form-item label="" prop="unit">
            <el-input
              v-model.trim="formData.unit"
              style="width: 200px"
              maxlength="2"
              placeholder="请输入单位"
              :disabled="checked"
              type="text"
            ></el-input>
          </el-form-item>
          <div class="discuss">
            <el-checkbox
              v-model="checked"
              :disabled="formData.price !== '' || formData.unit !== ''"
              >面议</el-checkbox
            >
          </div>
          <div class="merchant">
            <el-form-item label=" " prop="address" style="margin-left: 30px">
              <el-input
                v-model.trim="formData.address"
                placeholder="请输入商家地址"
                maxlength="50"
                prefix-icon="hy-icon-dizhi"
                @input="addressInput"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div>
          <editor @input="getval"></editor>
        </div>
        <div class="issuebtn">
          <el-button type="primary" @click="trimClick">提交</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import editor from "./components/editor.vue";
import { listByDictType, serviceGood, ossUploadFile } from "@/api/public";
import { getCompanyAuthDetail } from "@/api/userCenters.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      fileList: [],
      htmlForEditor: "",
      checked: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      formData: {
        concateUser: "",
        imgUrlList: [],
        dictId: "",
        name: "",
        phone: "",
        price: "",
        address: "",
        content: "",
        serviceProviderId: "",
        unit: "",
        addressCode: "",
      },
      imgUrlList: [],
      rules: {
        imgUrlList: [
          {
            required: true,
            message: "请上传商品图片",
            trigger: "change",
          },
        ],
        dictId: [
          { required: true, message: "请选择服务范围", trigger: "change" },
        ],
        name: [{ required: true, message: "请填写服务名称", trigger: "blur" }],
        concateUser: [
          { required: true, message: "请填写联系人名称", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请填写联系电话", trigger: "blur" }],
        price: [{ required: false, message: "请填写价格", trigger: "blur" }],
        address: [
          { required: true, message: "请填写商家地址", trigger: "blur" },
        ],
        unit: [{ required: false, message: "请输入单位", trigger: "blur" }],
      },
      options: [],
    };
  },
  components: {
    editor,
  },
  mounted() {
    this.formData.serviceProviderId = this.$route.query.serviceProviderId;
    this.getparentLevel();
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    nameinput(e) {
      var patrn = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
      if (!patrn.test(e)) {
        // 如果包含特殊字符返回false
        var str1 = this.formData.name.substr(1); //删除首字符
        var str2 = str1.substring(0, str1.length - 1); //删除最后末字符
        this.formData.name = str2;
        return false;
      } else {
        this.formData.name = e;
      }
      // return true;
    },
    concateUserInput(e) {
      console.log(e);
      this.formData.concateUser = e;
    },
    phoneInput(e) {
      if (/[^0-9]/g.test(e)) {
        this.formData.phone = "";
      } else {
        this.formData.phone = e;
      }
    },
    priceInput(e) {
      if (/[^0-9]/g.test(e)) {
        this.formData.price = "";
      } else {
        this.formData.price = e;
      }
      console.log(e, this.formData.price);
    },
    addressInput(e) {
      // this.formData.address = e;
      var patrn = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
      if (!patrn.test(e)) {
        // 如果包含特殊字符返回false
        var str1 = this.formData.address.substr(1); //删除首字符
        var str2 = str1.substring(0, str1.length - 1); //删除最后末字符
        this.formData.address = str2;
        return false;
      } else {
        this.formData.address = e;
      }
    },
    //上传图片格式大小检查
    checkPic(file) {
      let isJPG;
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        isJPG = true;
      } else {
        isJPG = false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("请上传jpg,jpeg,png图片!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    beforeUpload(file) {
      console.log(file);
      let pass = this.checkPic(file);
      if (!pass) {
        return false;
      }
    },
    addinvoiceUrl(file) {
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.imgUrlList.push({
            name: res.data,
            id: otherFiles.uid,
          });
          console.log(this.imgUrlList);
          // this.form.invoiceUrl = res.data;
          // this.$refs.invoiceForm.validateField(["invoiceUrl"]);
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-50);
    },
    getparentLevel() {
      // this.formData.concateUser = localStorage.getItem("userName");
      this.formData.phone = localStorage.getItem("phone");
      getCompanyAuthDetail().then((res) => {
        console.log(res);

        this.formData.concateUser = res.data.legalName;
      });
      let dictType = "service_range";
      listByDictType(dictType).then((res) => {
        if (res.code == 0) {
          this.options = res.data;
        }
      });
    },
    getval(val) {
      // console.log(val.match(/img/g))
      this.formData.content = val;
    },
    trimClick() {
      console.log(this.imgUrlList);
      let namesArr = this.imgUrlList.map((item) => {
        console.log(item);
        return item.name;
      });
      console.log(namesArr);
      this.formData.imgUrlList = namesArr;
      console.log(this.formData);
      this.$refs.receivingForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          if (this.formData.price == "" && !this.checked) {
            this.$message.error("请输入价格");
            return false;
          }
          // else if (this.formData.unit == "" && !this.checked) {
          //   this.$message.error("请输入单位");
          //   return false;
          // }
          let config = this.formData;
          serviceGood(config).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$router.go(-1);
              this.$message({
                message: "发布成功,请等待审核!",
                type: "success",
              });
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
        }
      });
    },
    handleRemove(file) {
      for (let i = 0; i < this.imgUrlList.length; i++) {
        if (this.imgUrlList[i].id == file.uid) {
          this.imgUrlList.splice(i, 1);
        }
      }
      console.log(file.uid, this.imgUrlList);
    },
    handleDownload(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select_dict {
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  width: 466px;
  .el-input__inner {
    border: none;
  }
  img {
    width: 14px;
    height: 16px;
    margin-left: 5px;
  }
}
::v-deep .icon_png {
  padding-left: 5px;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  width: 466px;
  img {
    width: 18px;
    height: 15px;
  }
  input {
    border: none;
    // width: 200px;
  }
}
.issueServe {
  width: 65%;
  min-width: 1200px;
  //   height: 100vh;
  margin: 0 auto;
  background: #fff;
  padding: 22px 28px;
  .issu_imagenum {
    font-size: 10.26px;
    color: red;
    margin-left: 5px;
  }
  .issue_up {
    margin-top: 24px;
  }
}
.line {
  display: flex;
  //   align-items: center;
  //   margin-left: 140px;

  .el-input {
    width: 466px;
  }
  .el-select {
    width: 466px;
  }
}
::v-deep .el-form-item__content {
  margin-left: 0px !important;
}
::v-deep .el-form-item {
  display: flex;
}
.discuss {
  line-height: 42px;
  margin-left: 15px;
}
.lines {
  display: flex;
  .mony_inp {
  }

  .merchant {
    .el-input {
      width: 809px;
    }
  }
}
.issuebtn {
  text-align: center;
  margin-top: 11px;
  button {
    width: 308px;
    height: 34px;
  }
}
</style>
